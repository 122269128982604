
function handleDocumentClick (e: MouseEvent): void {
    if (!e.target) {
        return;
    }
    const clickedElem = <HTMLElement>e.target;
    // don't close if click in mega layer
    if (clickedElem.closest('.megamenu-item.show')) {
        return;
    }
    // get all mega menu elements
    const megaMenuItems = document.querySelectorAll<HTMLDivElement>('.megamenu-item');
    if (!megaMenuItems) {
        return;
    }
    megaMenuItems.forEach((elem) => {
        const bsCollapse = window.bootstrap.Collapse.getInstance(elem);
        if (bsCollapse) {
            bsCollapse.hide();
        }
    });
}

function removeSubMenuContent (): void {
    const subMenuContentElem = document.querySelector('.offcanvas-body .submenu-wrapper .submenu-content');
    if (subMenuContentElem) {
        subMenuContentElem.innerHTML = '';
    }
}

export class MegaMenuHandler {
    private readonly megaMenuPageType = 500;
    private readonly megaMenuItemSelector = '.js-dropdown-mega';
    private readonly mobileNavItemSelector = '.js-nav-next';

    protected lang = '';
    protected megaMenuUrl = '/index.php?';

    constructor (lang: string) {
        this.lang = lang;
        if (lang === 'fr') {
            this.megaMenuUrl += 'L=1&';
        }

        this.load();
        this.handleMobileNavLinks();
    }

    load (): void {
        const megaMenuNavItemList = document.querySelectorAll<HTMLLIElement>(this.megaMenuItemSelector);
        if (!megaMenuNavItemList) {
            return;
        }
        const megaMenuContainer = <HTMLDivElement>document.querySelector('.megamenu-container .container');
        if (!megaMenuContainer) {
            return;
        }

        let currentMegaMenu = '';
        megaMenuNavItemList.forEach((navItem) => {
            const pid = <string>navItem.dataset.pid;
            if (pid) {
                fetch(`${this.megaMenuUrl}type=${this.megaMenuPageType}&id=${pid}`, {
                    method: 'GET'
                }).then((response): Promise<string> => {
                    if (response.status === 200) {
                        return response.text();
                    } else {
                        return new Promise((resolve) => { resolve(''); });
                    }
                }).then((content) => {
                    if (content !== '') {
                        const innerDiv = document.createElement('div');
                        innerDiv.classList.add('inner');
                        innerDiv.innerHTML = content;

                        const megaMenuWrap = document.createElement('div');
                        megaMenuWrap.classList.add('megamenu-item');
                        megaMenuWrap.classList.add('collapse');
                        megaMenuWrap.id = `mMenuItem${pid}`;
                        megaMenuWrap.dataset.bsParent = '.megamenu-container';
                        megaMenuWrap.append(innerDiv);
                        megaMenuContainer.append(megaMenuWrap);

                        megaMenuWrap.addEventListener('hide.bs.collapse', function (this: HTMLDivElement) {
                            if (currentMegaMenu === this.id) {
                                document.removeEventListener('click', handleDocumentClick, true);
                            }
                        });
                        megaMenuWrap.addEventListener('show.bs.collapse', function (this: HTMLDivElement) {
                            document.addEventListener('click', handleDocumentClick, true);
                            currentMegaMenu = this.id;
                        });
                    }
                    return '';
                }).catch(() => { return ''; });
            }
        });
    }

    handleMobileNavLinks (): void {
        const menuWrapperElem = document.querySelector('.offcanvas-body .menu-wrapper');
        const subMenuWrapperElem = document.querySelector('.offcanvas-body .submenu-wrapper');
        const mobileNavItemList = document.querySelectorAll<HTMLLinkElement>(this.mobileNavItemSelector);
        if (!mobileNavItemList || !subMenuWrapperElem || !menuWrapperElem) {
            return;
        }
        mobileNavItemList.forEach((navItem) => {
            navItem.addEventListener('click', function (e) {
                const linkElem = <HTMLLinkElement>e.target;
                if (!linkElem) {
                    return;
                }
                const pid = linkElem.dataset.pid;
                if (!pid) {
                    return;
                }
                // const link = this.dataset.link;
                const megaMenuContentElem = document.querySelector(`#mMenuItem${pid} .inner`);
                const subMenuContentElem = subMenuWrapperElem.querySelector('.submenu-content');
                const subMenuBackLink = subMenuWrapperElem.querySelector('.js-nav-up');
                if (!megaMenuContentElem || !subMenuContentElem || !subMenuBackLink) {
                    return;
                }
                const clonedMegaMenuContent = megaMenuContentElem.cloneNode(true);
                subMenuContentElem.replaceChildren(clonedMegaMenuContent);
                subMenuBackLink.textContent = linkElem.textContent;
                subMenuWrapperElem.classList.add('is-visible');
                menuWrapperElem.classList.add('is-hidden');
            });
        });

        const navUpLink = document.querySelector('.offcanvas-body .js-nav-up');
        if (!navUpLink) {
            return;
        }
        navUpLink.addEventListener('click', function (e) {
            const linkElem = <HTMLLinkElement>e.target;
            if (!linkElem) {
                return;
            }
            const subMenuWrapperElem = linkElem.closest('.submenu-wrapper');
            if (subMenuWrapperElem && menuWrapperElem) {
                menuWrapperElem.classList.remove('is-hidden');
                subMenuWrapperElem.classList.remove('is-visible');
                setTimeout(removeSubMenuContent, 500);
            }
        });
    }
}
